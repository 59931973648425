module.exports = {
  title: 'Colin Ross', // Required
  author: 'Colin Ross', // Required
  description: 'Colin\'s blog',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://colinscape.com',
    github: 'https://github.com/colinscape',
    twitter: 'https://twitter.com/colinscape',
    linkedin: 'https://uk.linkedin.com/in/colinscape',
  },
  pathPrefix: '/',
  siteUrl: 'https://colinscape.com/',
};
